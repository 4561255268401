<template>
  <div>
    <!-- crate flex to create form filter -->
    <div class="d-flex justify-content-start my-2 mx-2">
      <b-form-input
        placeholder="Cari nama produk atau SKU"
        class="mr-2 w-50"
        size="md"
      />
      <v-select
        class="select-size-md w-100 mr-2"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        multiple
        label="title"
        :options="shopOptionsOnlyLazada"
        placeholder="Pilih Toko"
      >
        <template #option="{ title, icon }">
          <b-img
            :src="require('@/assets/images/marketplace/' + icon + '.png')"
            :alt="title"
            style="width: 24px; height: 24px"
          />
          <span> {{ title }}</span>
        </template>
      </v-select>
      <b-button variant="primary" size="md" class="w-25">
        <Feather-icon :icon="'SearchIcon'" />
        Cari
      </b-button>
    </div>

    <b-table striped :items="data.items" :fields="fields" responsive="lg" small>
      <template #cell(name)="data">
        <b-img
          :src="marketplaceIcon('lazada')"
          :alt="data.value"
          style="width: 24px; height: 24px"
          class="mr-1 ml-1"
        />
        <span>{{ data.value }}</span>
      </template>
      <template #cell(product.attributes.name)="data">
        <div class="item-container d-flex">
          <div class="item-thumbnail mr-1">
            <b-img
              class="item-image"
              :alt="data.value"
              :src="`https://cdn.statically.io/avatar/${data.value}`"
              style="
                width: 44px;
                height: 44px;
                object-fit: cover;
                object-position: center;
              "
            />
          </div>
          <div class="d-flex flex-column">
            <h5 class="text-primary mb-1">
              <b>{{ data.value }}</b>
            </h5>
            <div
              v-for="(k, index) in data.item.product.skus"
              :key="index"
              class="item-id"
            >
              <small>
                <b> SKU #{{ index + 1 }} </b>
              </small>
              <ul>
                <li>Status Produk : {{ k.Status }}</li>
                <li>Stok Produk : {{ stockWithDot(k.quantity) }}</li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <template #cell(product.skus)="data">
        <!-- loop skus -->
        <div v-for="(sku, index) in data.item.product.skus" :key="index">
          <small
            ><b>SKU #{{ index + 1 }}</b></small
          >
          <ul>
            <li>SKU Toko : {{ sku.ShopSku }}</li>
            <li>SKU Penjual : {{ sku.SellerSku }}</li>
          </ul>
          <br v-if="data.item.product.skus.length > 1" />
        </div>
      </template>
      <template #cell(price)="data">
        <div v-for="(i, index) in data.item.product.skus" :key="index">
          <small
            ><b>SKU #{{ index + 1 }}</b></small
          >
          <ul>
            <li>Harga : {{ priceToRupiah(i.price) }}</li>
            <li>Harga Spesial : {{ priceToRupiah(i.special_price) }}</li>
          </ul>
          <br v-if="data.item.product.skus.length > 1" />
        </div>
      </template>

      <template #cell(action)="">
        <b-button size="sm" variant="outline-primary" block>
          <Feather-icon :icon="'LinkIcon'" />
          Tautkan
        </b-button>
      </template>
    </b-table>

    <div class="pagination-container">
      <small
        >menampilkan {{ data.items.length }} dari {{ data.total }} data</small
      >
      <b-pagination
        v-model="data.page"
        :total-rows="data.total"
        :per-page="data.perPage"
        aria-controls="my-table"
        class="mt-2 ml-2"
        size="lg"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BPagination,
  BImg,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BFormSelect,
  BFormInput,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import vSelect from 'vue-select';
import axios from '@axios';

export default defineComponent({
  components: {
    BTable,
    BPagination,
    BImg,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BFormSelect,
    BFormInput,
    vSelect,
  },
  props: {
    shopOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Toko',
          thStyle: 'width: 15%',
          thClass: 'text-center',
        },
        {
          key: 'product.attributes.name',
          label: 'Nama Produk',
          thStyle: 'width: 35%',
          thClass: 'text-center',
        },
        {
          key: 'product.skus',
          label: 'SKU',
          thStyle: 'width: 20%',
          thClass: 'text-center',
        },
        {
          key: 'price',
          label: 'Harga',
          thStyle: 'width: 20%',
          thClass: 'text-center',
        },
        {
          key: 'action',
          label: '',
          thStyle: 'width: 10%',
          thClass: 'text-center',
        },
      ],
      data: {
        page: 1,
        perPage: 10,
        total: 0,
        items: [],
      },
      shopOptionsOnlyLazada: this.shopOptions.filter(
        shop => shop.icon === 'lazada',
      ),
    };
  },
  methods: {
    async fetchData(page = 1) {
      const url = 'https://api.kanaldagang.com/lazada/product/sync?ns=false';

      const response = await axios.get(url, {
        params: {
          page,
          perPage: this.data.perPage,
        },
      });

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.success === true &&
        response.data.data.data.length > 0
      ) {
        // add sortable to true
        // const data = response.data.data.data.forEach(item => {
        //   item.sortable = true;
        // });

        this.data.items = response.data.data.data;
        this.data.total = response.data.data.total;
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
  // watch page
  watch: {
    'data.page': 'fetchData',
  },
  computed: {
    marketplaceIcon() {
      return marketplace =>
        // eslint-disable-next-line import/no-dynamic-require, global-require
        require(`@/assets/images/marketplace/${marketplace}.png`);
    },
    priceToRupiah() {
      return price => {
        const rupiah = new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(price);

        return rupiah;
      };
    },
    stockWithDot() {
      return stock => stock.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
});
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.item-container {
  display: flex;
  align-items: center;
}

.item-thumbnail {
  width: 44px;
  height: 44px;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.item-details {
  flex-grow: 1;
}

.item-name {
  font-weight: bold;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
}

.item-id {
  font-size: smaller;
}

.item-category {
  font-size: smaller;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  /* Add additional styling as needed */
}

/* tr td thead using bg-transparent */
.table thead th {
  background-color: transparent !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.w-200 {
  width: 200px;
}
</style>
